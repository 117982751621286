<template>
  <v-container class="mt-16">
    <p class="text-h5 text-center white--text">Paypayからの入金を確認中です</p>
    <p class="text-subtitle-1 text-center white--text">自動で遷移するまでお待ち下さい</p>
  </v-container>
</template>

<script>
import Api from '@/modules/api'
import store from '../store'
export default {
  name: 'PaypayConfirmed',
  data(){
    return {

    }
  },
  mounted() {
    const merchantPaymentId = this.$route.query.merchantPaymentId
    console.log(merchantPaymentId)
    const maxRetries = 5;
    let retries = 0;
    const intervalId = setInterval(() => {
      const jwtToken = store.getters.jwtToken;
      console.log("retries: ",retries)
      if (jwtToken !== null) {
        clearInterval(intervalId); // ループを終了
        Api.postPaypayPayment(merchantPaymentId).then(res => {
          console.log(res)
          this.$router.push({name: 'home'})
        })
      } else if (retries >= maxRetries) {
        // 最大リトライ回数に達した場合の処理をここに書く
        console.log('最大リトライ回数に達しました');
        clearInterval(intervalId); // ループを終了
      } else {
        retries++;
      }
    }, 2000);

    // Api.postPaypayPayment(merchantPaymentId).then(res => {
    //   console.log(res)
    //   this.$router.push({name: 'home'})
    // })
  },
}
</script>