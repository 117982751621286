<template>
  <div>
    <v-container class="mh" style="max-width:800px;">
      <v-container>
        <!-- <p class="white--text" style="font-size:12px;">
          <a href="https://twitter.com/ArcaraOripa" target="_blank" style="background-color:white;"> Twitter </a>では連続プレゼント企画を行っていますのでぜひ参加してみてください!!<br>
          たくさんのご応募お待ちしております!!
        </p> -->
        <!-- <p v-if="oripas.length==0" class="text-body1 white--text text-center">現在公開されているオリパはありません</p> -->
        <v-row>
          <v-col v-if="bonuses.length>0" cols="12" sm="6" md="6" lg="6">
            <BonuseCard v-if="bonuses.filter(oripa => oripa.oripa_name=='login_bonus_coin').length>0" :oripa="bonuses.filter(oripa => oripa.oripa_name=='login_bonus_coin')[0]"></BonuseCard>
          </v-col>
          <v-col v-if="bonuses.length>0" cols="12" sm="6" md="6" lg="6">
            <BonuseCard v-if="bonuses.filter(oripa => oripa.oripa_name=='login_bonus_box').length>0" :oripa="bonuses.filter(oripa => oripa.oripa_name=='login_bonus_box')[0]"></BonuseCard>
          </v-col>
          <v-col v-for="oripa in oripas" :key="oripa.oripa_id" cols="12" sm="6" md="6" lg="6">
            <ThumbnailCard :oripa="oripa" :pattern="oripa.design"></ThumbnailCard>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-footer padless>
      <v-card class="flex" flat tile color="black">
        <v-container>
          <v-card-text class="white--text">
            <v-row>
              <v-col cols="auto"><p class="font-weight-bold mb-0">Arcaraについて</p></v-col>
            </v-row>
            <v-row>
              <v-col cols="auto"><router-link to="/tos"><p class="mb-0">利用規約</p></router-link></v-col>
              <v-col cols="auto"><router-link to="/guide"><p class="mb-0">ご利用方法</p></router-link></v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="auto"><router-link to="/privacypolicy"><p class="mb-0">プライバシーポリシー</p></router-link></v-col>
              <v-col cols="auto"><router-link to="/sct"><p class="mb-0">特定商取引法に基づく表記</p></router-link></v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="auto"><router-link to="/contact"><p class="mb-0">お問い合わせ</p></router-link></v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="white--text">
            <v-row align="center" no-gutters>
              <v-col cols="auto"><v-img :src="require('@/assets/Logo/logo6.svg')" width="110"></v-img></v-col>
              <v-col cols="auto"><p class="mb-0 pl-2 pt-2 text-caption">© {{new Date().getFullYear()}} yosugara Inc.</p></v-col>
              <v-spacer></v-spacer>
              <v-col><p class="mb-0 text-caption">v1.0.2</p></v-col>
            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import store from '../store'
import Api from '@/modules/api'
import Utils from '@/modules/utils'
import ThumbnailCard from '../components/ThumbnailCard.vue'
import BonuseCard from '../components/BonuseCard.vue'

export default {
  name: 'HomeView',
  components: {
    ThumbnailCard,
    BonuseCard,
  },
  data(){
    return {
      skill: 50.5,
      bonuses: [],
      oripas: [],
      cards: [],
    }
  },
  mounted: function(){
    Api.getCardsStockBlank().catch(() => {
      this.openSnackbar('カード一覧を読み込めませんでした.')
    })
    Api.getOripas().then(res => {
      let data = res["data"]
      let now = new Date();
      data.sort((a,b) => {
        // if(a.sale_ts !== b.sale_ts){
        //   if(a.sale_ts > b.sale_ts) return -1
        //   if(a.sale_ts < b.sale_ts) return 1
        // }
        // return 0
        // 発売日時が過ぎているかどうかで比較する
        let aReleased = this.toDate(a.sale_ts) <= now;
        let bReleased = this.toDate(b.sale_ts) <= now;
        
        // aが発売済みの場合
        if (aReleased) {
          // bも発売済みの場合は、発売日時の降順に並べる
          if (bReleased) {
            return this.toDate(b.sale_ts) - this.toDate(a.sale_ts);
          }
          // bがまだ発売していない場合は、aを先頭に並べる
          else {
            return -1;
          }
        }
        // aがまだ発売していない場合
        else {
          // bもまだ発売していない場合は、発売日時の昇順に並べる
          if (!bReleased) {
            return this.toDate(a.sale_ts) - this.toDate(b.sale_ts);
          }
          // bが発売済みの場合は、bを先頭に並べる
          else {
            return 1;
          }
        }
      })
      this.bonuses = data.filter(obj => obj['publish']===2);
      data = data.filter(obj => obj['publish']===1);
      this.moveElementsToEnd(data, x => this.getRestLot(x)==0);
      this.oripas = data
    }).catch(() => {
      this.openSnackbar('オリパ一覧を読み込めませんでした.')
    })
  },
  methods: {
    ...mapActions(["openSnackbar", "closeSnackbar"]),
    getRestLot: function(oripa){
      return oripa['all_rest_lot']
      // const oripa_stock = oripa["oripa_stock"]
      // return oripa_stock.map(el => el["rest_lot"]).reduce(function(sum, element){return sum + element;}, 0);
    },
    getAllLot: function(oripa){
      return oripa['all_lot']
      // const oripa_stock = oripa["oripa_stock"]
      // return oripa_stock.map(el => el["lot"]).reduce(function(sum, element){return sum + element;}, 0);
    },
    toDate: function(sale_ts){
      const sale_ts_date = sale_ts.split('T')[0].split('-')
      const sale_ts_time = sale_ts.split('T')[1].split(':')
      const saleTime = new Date(Number(sale_ts_date[0]),Number(sale_ts_date[1])-1,Number(sale_ts_date[2]),Number(sale_ts_time[0]),Number(sale_ts_time[1]),Number(sale_ts_time[2]))
      return saleTime
    },
    moveElementsToEnd: function(arr, condition) {
      let i = 0;
      let j = arr.length - 1;
      while (i < j) {
        if (condition(arr[i])) {
          // 要素を末尾に移動
          [arr[i], arr[j]] = [arr[j], arr[i]];
          j--;
        } else {
          i++;
        }
      }
      return arr;
    }

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/holo.scss';
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);

/*.bn632-hover {*/
/*  width: 160px;*/
/*  font-size: 16px;*/
/*  font-weight: 600;*/
/*  color: #fff;*/
/*  cursor: pointer;*/
/*  margin: 20px;*/
/*  height: 55px;*/
/*  text-align:center;*/
/*  border: none;*/
/*  background-size: 300% 100%;*/
/*  border-radius: 50px;*/
/*  moz-transition: all .4s ease-in-out;*/
/*  -o-transition: all .4s ease-in-out;*/
/*  -webkit-transition: all .4s ease-in-out;*/
/*  transition: all .4s ease-in-out;*/
/*}*/

.bn632-hover:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.bn632-hover:focus {
  outline: none;
}

.bn26 {
  background-image: linear-gradient(
    to right,
    #25aae1,
    #4481eb,
    #04befe,
    #3f86ed
  );
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}

.canvas {
  border: 1px solid #000;
  width:100%;
}

.tham {
  position:absolute;
  z-index:7;
  top:50%;
  left:25%;
  opacity: 0.9;
  transform: translate(-50%, -50%) rotate(-10deg);
  -webkit-transform: translate(-50%, -50%) rotate(-10deg);
  -ms-transform: translate(-50%, -50%) rotate(-10deg);
}
.tham_sub {
  position:absolute;
  opacity: 0.9;
  transform: translate(-50%, -50%) rotate(-20deg);
  -webkit-transform: translate(-50%, -50%) rotate(-20deg);
  -ms-transform: translate(-50%, -50%) rotate(-20deg);
}
.sub1 {
  top:30%;
  left:60%;
  z-index:6;
}
.sub2 {
  top:30%;
  left:75%;
  z-index:5;
}
.sub3 {
  top:30%;
  left:90%;
  z-index:4;
}
.sub4 {
  top:70%;
  left:60%;
  z-index:3;
}
.sub5 {
  top:70%;
  left:75%;
  z-index:2;
}
.sub6 {
  top:70%;
  left:90%;
  z-index:1;
}
.tham_title {
  position:absolute;
  background: linear-gradient(to top, rgba(225, 225, 225, 0.85),transparent);
  z-index:10;
  width:100%;
  bottom:0;
  padding-left:30px;
  padding-top:30px;
  margin-bottom:0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size:25px;
  color:white;
  font-weight: bold;
  /*font-family: 'Reggae One', cursive;*/
  font-family: 'RocknRoll One', sans-serif;
  text-shadow: 2px  2px 3px black,-2px  2px 3px black,2px -2px 3px black,-2px -2px 3px black;
}
.lot {
  font-size:15px;
}
.tham_price {
  position:absolute;
  z-index:10;
  top:10px;
  right:10px;
  font-weight:bold;
}
.progress_bar {
  color:white;
  /*font-family: 'Reggae One', cursive;*/
  font-family: 'RocknRoll One', sans-serif;
  text-shadow: 2px  2px 3px black,-2px  2px 3px black,2px -2px 3px black,-2px -2px 3px black;
}

.mh {
  min-height: 100vh;
}
</style>