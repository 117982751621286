export default {
  AWSConfig: {
    // Region: 'ap-northeast-1',
    // UserPoolId: 'ap-northeast-1_Eerm4M0Au',
    // ClientId: '4u0rvn3kljv96k23koh6lvuih7',
    // IdentityPoolId: 'ap-northeast-1:087a0528-8728-4621-932f-6d801f37ae57',
    Region: 'ap-northeast-1',
    UserPoolId: process.env.VUE_APP_USER_POOL_ID,
    ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    IdentityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
  }
}