<template>
  <div>
    <Thumbnail1 v-if="pattern==1" :oripa="oripa"></Thumbnail1>
    <Thumbnail2 v-else-if="pattern==2" :oripa="oripa"></Thumbnail2>
    <Thumbnail3 v-else-if="pattern==3" :oripa="oripa"></Thumbnail3>
    <Thumbnail4 v-else-if="pattern==4" :oripa="oripa"></Thumbnail4>
    <Thumbnail5 v-else-if="pattern==5" :oripa="oripa"></Thumbnail5>
    <Thumbnail6 v-else-if="pattern==6" :oripa="oripa"></Thumbnail6>
    <Thumbnail7 v-else-if="pattern==7" :oripa="oripa"></Thumbnail7>
    <Thumbnail8 v-else-if="pattern==8" :oripa="oripa"></Thumbnail8>
    <Thumbnail9 v-else-if="pattern==9" :oripa="oripa"></Thumbnail9>
    <Thumbnail1 v-else :oripa="oripa"></Thumbnail1>
  </div>
</template>

<script>
import Thumbnail1 from './ThumbnailPatterns/Thumbnail1.vue'
import Thumbnail2 from './ThumbnailPatterns/Thumbnail2.vue'
import Thumbnail3 from './ThumbnailPatterns/Thumbnail3.vue'
import Thumbnail4 from './ThumbnailPatterns/Thumbnail4.vue'
import Thumbnail5 from './ThumbnailPatterns/Thumbnail5.vue'
import Thumbnail6 from './ThumbnailPatterns/Thumbnail6.vue'
import Thumbnail7 from './ThumbnailPatterns/Thumbnail7.vue'
import Thumbnail8 from './ThumbnailPatterns/Thumbnail8.vue'
import Thumbnail9 from './ThumbnailPatterns/Thumbnail9.vue'

export default {
  name: 'AllThumbnail',
  props: ['oripa','pattern'],
  components: {
    Thumbnail1,
    Thumbnail2,
    Thumbnail3,
    Thumbnail4,
    Thumbnail5,
    Thumbnail6,
    Thumbnail7,
    Thumbnail8,
    Thumbnail9,
  },
}
</script>