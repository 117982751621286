<template>
  <v-container style="max-width:800px;" class="white--text mb-15">
    <h2 class="text-center mt-15 mb-10">Arcaraから退会する</h2>
    <h3>注意事項</h3>
    <ul>
      <li>退会すると同じアカウントには二度とアクセスできません。</li>
      <li>退会後、同じメールアドレスで新規登録した場合でも同じアカウントは使えません。</li>
      <li>保有中のptやカード、その他すべてのデータは消失します。</li>
      <li>配送準備中の（配送希望に出してから次の配送確定日を過ぎている）カードは配送されます。</li>
      <li>配送希望中の（配送希望に出してから次の配送確定日に至っていない）カードは配送されず、消失されることがあります。配送希望に出しているカードが存在する場合、配送確定日が過ぎてからの退会を推奨いたします。</li>
    </ul>
    <v-row>
      <v-btn class="mt-15 mx-auto" @click="dialog=true">退会する</v-btn>
    </v-row>
    <v-dialog v-model="dialog" width="350px">
      <v-card>
        <v-card-title>本当に退会する</v-card-title>
        <v-card-subtitle>この操作は取り消せません</v-card-subtitle>
        <v-card-text>
          <v-btn block color="#004B78" class="white--text" @click="withdrawal">退会する</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Utils from '@/modules/utils'

export default {
  name: 'Withdrawal',
  data(){
    return {
      dialog: false
    }
  },
  methods: {
    ...mapActions(["openSnackbar", "closeSnackbar"]),
    withdrawal: function(){
      this.$cognito.deleteUser().then(() => {
        this.openSnackbar('👋 正常に退会いたしました.')
        Utils.logout()
      }).catch(err => {
        console.log(err)
        this.openSnackbar('退会に失敗しました.')
      }).finally(() => {
        this.dialog = false
        setTimeout(() => {this.disabledSent = false},2000)
      })
    }
  }
}
</script>