import axios from 'axios'
import store from '../store'
import Utils from './utils'

const baseUrl = process.env.VUE_APP_API_ORIGIN

axios.interceptors.response.use(
  function(response){
    return Promise.resolve(response)
  },
  function(error){
    console.log("error: ",error)
    switch (error.response?.status){
      case 401:
        Utils.logout()
      default:
        return Promise.reject(error)
    }
  }
)

export default {
  getOripas(){
    console.log("=====get_oripa_all_open======")
    const url = baseUrl + "/oripa/open"
    return new Promise((resolve,reject) => {
      axios.get(url,{}).then(res => {
        store.commit('setOripas',res["data"])
        console.log(res["data"])
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getCardsStockBlank(){
    console.log("=====get_cards_stock_blank======")
    const url = baseUrl + "/cards/stock/blank"
    return new Promise((resolve,reject) => {
      axios.get(url,{}).then(res => {
        store.commit('setAllBlankCards',res["data"])
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getUser(){
    console.log("=====get_user======")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/user"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.get(url,{headers: headers}).then(res => {
        console.log(res["data"][0])
        store.commit('setUserData',res["data"][0])
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getMyCards(){
    console.log("=====get_my_cards======")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/cards"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.get(url,{headers: headers}).then(res => {
        store.commit('setMyCards',res["data"])
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getDeliveryHistory(){
    console.log("=====get_delivery_history=====")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/delivery_history"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.get(url,{headers: headers}).then(res => {
        store.commit('setDeliveryHistory',res["data"])
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getRankingOripaAll(){
    console.log("=====get_ranking_oripa_all======")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/ranking/oripa"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.get(url,{headers: headers}).then(res => {
        store.commit('setRanking',res["data"])
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getPayPayQRCode(price){
    console.log("=====get_paypay_qrcode======")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/user/paypay/qr"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.get(url,{
        params: {
          price: price
        },
        headers: headers
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getPaypayPayment(){
    console.log("=====get_paypay_payment======")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/user/paypay/payment"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.get(url,{
        headers: headers
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  postPaypayPayment(merchantPaymentId){
    console.log("=====post_paypay_payment======")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/user/paypay/payment"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.post(url,{
        merchantPaymentId: merchantPaymentId
      },{
        headers: headers
      }).then(res => {
        this.getUser()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  postOripaGacha(id,num){
    console.log("=====gacha======")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/oripa/" + id
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.post(url,{
        currency: 'points',
        number: num
      },{
        headers: headers
      }).then(res => {
        this.getMyCards()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  postBonuseGacha(id){
    console.log('=====bonuse_gacha=====')
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/bonuse/" + id
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.post(url,{},{
        headers: headers
      }).then(res => {
        this.getMyCards()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  postCardsReturn(cards){
    console.log("=====post_cards_return======")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/cards/return"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.post(url,{
        orders: cards.map(item => item["order_id"])
      },{
        headers: headers
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  postRankingOripa(oripa_id){
    console.log("=====post_ranking_oripa======")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/ranking/oripa/" + oripa_id
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.post(url,{},{
        headers: headers
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  postSmsCreateCode(phone_number){
    console.log("=====post_sms_create_code======")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/sms/code"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.post(url,{
        phone_number: phone_number
      },{
        headers: headers
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  postSmsVerifyCode(id,code,phone_number){
    console.log("=====post_sms_verify_code======")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/sms/verify"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.post(url,{
        id: id,
        code: code,
        phone_number: phone_number
      },{
        headers: headers
      }).then(res => {
        this.getUser()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  putUser(profile){
    console.log("=====put_user=====")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/user"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.put(url,{
        params: profile
      },{
        headers: headers
      }).then(res => {
        console.log(res)
        resolve(res)
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  },
  putCardsDelivery(type,cards){
    console.log("=====put_cards_delivery=====")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/cards/delivery"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.put(url,{
        type: type,
        orders: cards.map(item => item["order_id"])
      },{
        headers: headers
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  putCoupon(coupon_code){
    console.log("=====put_coupon======")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/coupon"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.put(url,{
        coupon_code: coupon_code
      },{
        headers: headers
      }).then(res => {
        this.getUser()
        console.log(res)
        resolve(res)
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  },
  putPaypayPayment(merchantPaymentId){
    console.log("=====put_paypay_payment======")
    const jwtToken = store.getters.jwtToken
    const url = baseUrl + "/user/paypay/payment"
    const headers = { Authorization: jwtToken }
    return new Promise((resolve,reject) => {
      axios.put(url,{
        merchantPaymentId: merchantPaymentId
      },{
        headers: headers
      }).then(res => {
        this.getUser()
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
}