<template>
  <div>
    <v-container style="max-width:800px;">
      <v-card>
        <v-container>
          <v-card-actions>
            <v-btn text to="/"><v-icon>mdi-arrow-left-thin</v-icon>戻る</v-btn>
          </v-card-actions>
          <v-card-title>ポイント購入</v-card-title>
          <v-card-subtitle>所持ポイント：{{points}}pt</v-card-subtitle>
          <v-card-title>決済方法を選択</v-card-title>
          <v-card-text>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header><h4>クレジットカード決済</h4></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col v-for="(price, i) in prices" :key="price" cols="12" sm="6" md="6" lg="6">
                      <v-card>
                        <v-card-actions>
                          <v-icon class="mr-1">mdi-alpha-p-box-outline</v-icon>{{price}} pt
                          <v-spacer></v-spacer>
                          ¥{{price}}<v-btn class="ml-2 white--text" color="#bf9507" :href="stripeLink(i)" target="_blank">購入する</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-card>
                        <v-card-actions>
                          <v-icon class="mr-1">mdi-alpha-p-box-outline</v-icon>もっと追加
                          <v-spacer></v-spacer>
                          <v-btn class="ml-2 white--text" color="#bf9507" :href="stripeLink(5)" target="_blank">購入する</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header><h4>Paypay決済</h4></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>
                    Paypay画面で決済完了後、本サイトに戻るまで操作を行わないでください。<br>
                    Paypay決済が完了したにも関わらず、ポイントが反映されない場合は、下記の「未反映の可能性がある決済」から確認してください。
                  </p>
                  <v-row>
                    <v-col v-for="(price, i) in paypay_prices" :key="price" cols="12" sm="6" md="6" lg="6">
                      <v-card>
                        <v-card-actions>
                          <!-- <v-icon class="mr-1">mdi-alpha-p-box-outline</v-icon> -->
                          <p class="mb-0"><v-img src="@/assets/img/paypay_logo.jpg" width="30"></v-img></p>
                          <p class="mb-0">{{price}} pt</p>
                          <v-spacer></v-spacer>
                          <p class="mb-0">¥{{price}}</p>
                          <v-btn :loading="paypayLoading" class="ml-2 white--text" color="#bf9507" @click="paypayLink(price)">購入する</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-card class="mt-5">
                    <v-card-title style="font-size:16px;">未反映の可能性がある決済</v-card-title>
                    <v-card-subtitle>※Paypay決済がキャンセルされたものも表示されます。決済が完了しているもののみptが反映されます。</v-card-subtitle>
                    <v-card-text>
                      <v-btn v-if="showBtn" block @click="getPaypayPayment">表示</v-btn>
                      <v-row v-if="showBtn==false&&paypayPayments.length==0">
                        <v-col align="center">
                          <p class="mb-0">0件</p>
                        </v-col>
                      </v-row>
                      <v-row v-else-if="showBtn==false">
                        <v-col cols="12" v-for="payment in paypayPayments">
                          <v-card>
                            <v-card-text>
                              <v-row align="center">
                                <v-col><p class="mb-0">{{ payment.create_ts }}</p></v-col>
                                <v-col cols="auto"><v-btn :loading="paypayVerifyLoading" icon @click="verifyPayment(payment.merchantPaymentId)"><v-icon>mdi-refresh</v-icon></v-btn></v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="6"><p class="mb-0">ID: {{ blankID(payment.merchantPaymentId) }}</p></v-col>
                                <v-col cols="6" align="end"><p class="mb-0">{{ payment.price }}円</p></v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-title>クーポンを利用する</v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col cols="auto" sm="6" md="6" lg="6"><v-text-field v-model="code" outlined hide-details dense label="コードを入力する" color="#bf9507"></v-text-field></v-col>
              <v-col><v-btn class="white--text" color="#bf9507" @click="applyCoupon">適用</v-btn></v-col>
            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Api from '@/modules/api'
import store from '../store'
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { v4 as uuidv4 } from 'uuid';
import PAYPAY from '@/modules/paypay';

export default {
  name: 'points',
  components: {
    StripeCheckout,
  },
  data(){
    return {
      prices: [500,1000,2000,5000,10000],
      paypay_prices: [500,1000,2000,5000,10000,30000,50000],
      stripe_link: [
        process.env.VUE_APP_STRIPE_LINK_500,
        process.env.VUE_APP_STRIPE_LINK_1000,
        process.env.VUE_APP_STRIPE_LINK_2000,
        process.env.VUE_APP_STRIPE_LINK_5000,
        process.env.VUE_APP_STRIPE_LINK_10000,
        process.env.VUE_APP_STRIPE_LINK_MORE,
      ],
      code: '',
      paypayPaymentHeaders: [
        {text: 'ID', value: 'merchantPaymentId'},
        {text: '日時', value: 'create_ts'},
        {text: '金額', value: 'price', align: 'end'},
        {text: '確認', value: 'verify', align: 'center'},
      ],
      paypayPayments: [],
      showBtn: true,
      paypayLoading: false,
      paypayVerifyLoading: false,
    }
  },
  computed: {
    points: function(){
      const userData = store.getters.userData
      console.log("userData: ",userData)
      if(userData == null){
        return null
      }else{
        return userData["points"]
      }
    },
  },
  methods: {
    ...mapActions(["openSnackbar", "closeSnackbar"]),
    stripeLink: function(index){
      let user = this.$store.getters.user
      if(user != null){
        let user_id = user["cognito:username"]
        return this.stripe_link[index]+'?client_reference_id='+user_id
      }else{
        return ''
      }
    },
    applyCoupon: function(){
      if(this.code != ''){
        Api.putCoupon(this.code).then(() => {
          this.openSnackbar("クーポンコードを適用しました！")
        }).catch(err => {
          if(err.response?.status == 500){
            if(err.response.data == 'NotFoundCoupon') this.openSnackbar('無効なクーポンです')
            if(err.response.data == 'AlreadyUsed') this.openSnackbar('このクーポンは使用済みです')
            if(err.response.data == 'CouponExpired') this.openSnackbar('有効期限が切れています')
          }else{
            this.openSnackbar("なんらかのエラーが発生しました")
          }
        })
      }
    },
    blankID: function(id){
      return id.slice(0,8)
    },
    paypayLink: function(price){
      this.paypayLoading = true
      Api.getPayPayQRCode(price).then(res => {
        console.log(res)
        const qrcode = res["data"]["url"]
        window.location.href = res.data.url
      }).catch(err => {
        console.log(err)
        this.openSnackbar("なんらかのエラーが発生しました")
      })
    },
    getPaypayPayment: function(){
      Api.getPaypayPayment().then(res => {
        console.log(res)
        this.paypayPayments = res.data
        this.showBtn = false
      }).catch(err => {
        console.log(err)
        this.openSnackbar("なんらかのエラーが発生しました")
      })
    },
    verifyPayment: function(merchantPaymentId){
      this.paypayVerifyLoading = true
      Api.putPaypayPayment(merchantPaymentId).then(res => {
        console.log(res)
        this.getPaypayPayment()
        if(res.data.code == 'SUCCESS'){
          if(res.data.status == 'CREATED'){
            this.openSnackbar("決済がキャンセルされています")
          }else if(res.data.status == 'COMPLETED'){
            Api.getUser()
            this.openSnackbar("決済が完了しました")
          }else{
            this.openSnackbar("決済が正常に行われていません")
          }
        }else{
          this.openSnackbar("決済が正常に行われていません")
        }
        this.paypayVerifyLoading = false
      }).catch(err => {
        console.log(err)
        this.openSnackbar("なんらかのエラーが発生しました")
      })
    },
    // paypayLink: function(){
    //   const paymentId = uuidv4();
    //   const payload = {
    //     merchantPaymentId: paymentId,
    //     amount: {
    //       amount: 10000,
    //       currency: "JPY"
    //     },
    //     codeType: 'ORDER_QR',
    //     orderDescription: 'ポイント購入',
    //     // orderItems: [
    //     //   {
    //     //     name: '1000ポイント購入',
    //     //     quantity: 1,
    //     //     unitPrice: 1000,
    //     //   }
    //     // ],
    //     isAuthorization: false,
    //     redirectUrl: 'http://localhost:8080/paypay?paymentId=${merchantId}',
    //     redirectType: 'WEB_LINK',
    //   }
    //   PAYPAY.QRCodeCreate(payload).then(res => {
    //     console.log(res)
    //     window.location.href = res.data.url
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // }
  },
}
</script>