<template>
  <v-container v-if="showEff" fluid class="pa-0" style="background-color:black;">
    <v-btn style="position:absolute;top:10px;right:10px;background-color:#004B78;z-index:10;" class="white--text" @click="end">スキップ</v-btn>
    <video v-if="showEff" @ended="end" :width="videoWidth" :height="videoHeight" autoplay muted playsinline>
      <source v-if="eff==0" :src="require('@/assets/Effect/gacha_eff1.mp4')" />
      <source v-if="eff==1" :src="require('@/assets/Effect/gacha_eff10.mp4')" />
      <source v-if="eff==2" :src="require('@/assets/Effect/gacha_eff1_hit.mp4')" />
      <source v-if="eff==3" :src="require('@/assets/Effect/gacha_eff10_hit.mp4')" />
      <source v-if="eff==4" :src="require('@/assets/Effect/gacha_eff1_big.mp4')" />
      <source v-if="eff==5" :src="require('@/assets/Effect/gacha_eff10_big.mp4')" />
    </video>
  </v-container>
</template>

<script>
export default {
  name: 'Lottery',
  props: ['num','results'],
  data(){
    return {
      videoHeight: window.innerHeight-98.14 + "px",
      videoWidth: window.innerWidth + "px",
      showEff: false,
      eff: 0,
    }
  },
  mounted: function(){
    if(this.results == undefined){
      console.log(this.results)
      // this.$router.push('/')
    }else{
      if(this.results.map(item=>item[0]["prize"]).includes('S')){
        if(this.num == 1) this.eff = 4
        if(this.num == 10) this.eff = 5
      }else if(this.results.map(item=>item[0]["prize"]).includes('A')){
        if(this.num == 1) this.eff = 4
        if(this.num == 10) this.eff = 5
      }else if(this.results.map(item=>item[0]["prize"]).includes('B')){
        if(this.num == 1) this.eff = 2
        if(this.num == 10) this.eff = 3
      }else{
        if(this.num == 1) this.eff = 0
        if(this.num == 10) this.eff = 1
      }
      this.showEff = true
    }
  },
  methods: {
    end: function(){
      this.showEff = false
      this.$router.push({
        name: 'lottery-result',
        params: {
          results: this.results
        }
      })
    },
  }
}
</script>