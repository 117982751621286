<template>
  <div>
    <v-container>
      <v-card>
        <v-container>
          <v-card-actions>
            <v-btn text to="/mycards"><v-icon>mdi-arrow-left-thin</v-icon>戻る</v-btn>
          </v-card-actions>
          <v-card-title>まとめて還元する</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined @click="dialog=true">計{{selected.length}}枚を還元する</v-btn>
          </v-card-actions>
          <v-data-table v-model="selected" :headers="headers" :items="cardData.filter(card=>card.order_status==0)" :items-per-page="-1" show-select item-key="order_id">
            <template v-slot:item.image="{ item }" >
              <v-img :src="item.image" class="ma-1" width="70px"></v-img>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
      
      <v-dialog v-model="dialog">
        <v-card>
          <v-container>
            <v-card-actions>
              <v-btn text @click="dialog=false"><v-icon>mdi-arrow-left-thin</v-icon>戻る</v-btn>
            </v-card-actions>
            <v-card-title>以下{{selected.length}}枚のカードを還元してもよいですか？</v-card-title>
            <v-card-subtitle>合計{{sumReturnPoint(selected)}}還元P</v-card-subtitle>
            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">カード</th>
                      <th class="text-left">レアリティ</th>
                      <th class="text-left">収録パック</th>
                      <th class="text-left">リスト番号</th>
                      <th class="text-left">名前</th>
                      <th class="text-left">還元P</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in selected" :key="item.order_id">
                      <td><v-img :src="item.image" class="ma-1" width="50px"></v-img></td>
                      <td>{{ item.card_rarity }}</td>
                      <td>{{ item.pack_number }}</td>
                      <td>{{ item.card_list_number }}</td>
                      <td>{{ item.card_name }}</td>
                      <td>{{ item.return_point }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined @click="returnCards">還元する</v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
      
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import store from '../store'
export default{
  name: 'Returns',
  data(){
    return {
      cards: [],
      headers: [
        {text:'カード',value:'image'},
        {text:'レアリティ',value:'card_rarity'},
        {text:'収録パック',value:'pack_number'},
        {text:'リスト番号',value:'card_list_number'},
        {text:'名前',value:'card_name'},
        {text:'還元P',value:'return_point'},
      ],
      selected: [],
      dialog: false,
    }
  },
  computed: {
    cardData: function(){
      let cards = []
      let card = {}
      for(let i=0;i<this.cards.length; i++){
        card = this.cards[i]
        card["image"] = "https://www.pokemon-card.com/assets/images/card_images/large/"+card["card_id"]+".jpg"
        cards.push(card)
      }
      return cards
    },
  },
  mounted: function(){
    let user = this.$store.getters.user
    let myCards = this.$store.getters.myCards
    if(user != null){
      if(myCards == null){
        this.getMyCards(user["cognito:username"])
      }else{
        this.cards = myCards
      }
    }
  },
  methods: {
    ...mapActions(["openSnackbar", "closeSnackbar"]),
    getMyCards: function(user_id){
      const url = "https://wp4yigejf6.execute-api.ap-northeast-1.amazonaws.com/dev/cards"
      const jwtToken = store.getters.jwtToken
      if(jwtToken != null){
        const headers = {
          Authorization: jwtToken
        }
        this.axios.get(url,{
          headers: headers
        })
        .then(function(res){
          console.log(res)
          this.cards = res["data"]
          this.$store.commit('setMyCards',res["data"])
        }.bind(this)).catch(function(err){
          console.log(err)
        })
      }
    },
    card_image: function(card_id) {
      return "https://www.pokemon-card.com/assets/images/card_images/large/"+card_id+".jpg"
    },
    sumReturnPoint: function(objs){
      return objs.reduce((sum, obj) => sum + obj.return_point, 0);
    },
    returnCards: function(){
      const url = "https://wp4yigejf6.execute-api.ap-northeast-1.amazonaws.com/dev/cards/return"
      const jwtToken = store.getters.jwtToken
      if(jwtToken != null){
        const headers = {
          Authorization: jwtToken
        }
        this.axios.post(url,{
          orders: this.selected.map(item => item["order_id"])
        },{
          headers: headers
        }).then(function(res){
          console.log(res)
          this.getMyCards(this.$store.getters.user['cognito:username'])
          this.selected = []
          this.openSnackbar('👏 +' + res['data'] +'P')
          this.dialog = false
        }.bind(this)).catch(function(err){
          console.log(err)
          let statusCode = err['response']['status']
          let data = err['response']['data']
          this.openSnackbar(statusCode+':'+data)
        }.bind(this))
      }
    },
  },
}
</script>