<template>
  <v-card elevation="5" max-width="400" class="mx-auto" style="border-radius:15px;">
    <v-container style="background-color:#0086D2;border-radius:15px;">
      <v-img :src="require(`@/assets/img/${oripa.oripa_name}.png`)" height="150px" style="border:solid 3px;">
        <v-btn icon @click="dialog=true"><v-icon color="#bf9507">mdi-information-outline</v-icon></v-btn>
        <v-chip class="tham_price white--text" color="#bf9507" label style="border:solid 2px black;">
          残<span class="ml-1 mr-1" style="font-weight:bold;font-size:16px;">∞</span><span class="ml-3" style="font-weight:bold;font-size:16px;">{{oripa.price}}</span> pt
        </v-chip>
      </v-img>
      <v-row dense>
        <v-col>
          <v-chip-group column>
            <!-- <v-chip v-if="isSaled" small color="green" class="white--text" label>発売中</v-chip>
            <v-chip v-else small color="black" class="white--text" label>未発売</v-chip> -->
            <v-chip small color="#bf9507" class="white--text" v-for="(hashtag,index) in oripa.hashtags?oripa.hashtags.split(','):[]" :key="index"><v-icon x-small>mdi-pound</v-icon>{{ hashtag }}</v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <v-btn v-if="$store.getters.userData==null" block solo color="#bf9507" class="mt-2 white--text" style="border:solid black 2px;" to="/login">ログインしてボーナスGET!!</v-btn>
      <v-btn v-else-if="$store.getters.userData.bonuse_draw[oripa.oripa_id]" :disabled="isDisabled" block solo color="#bf9507" class="mt-2 white--text" style="border:solid black 2px;" @click="getBonus()">
        <span v-if="oripa.oripa_name=='login_bonus_box'">ボーナスを受け取る(期間限定)</span>
        <span v-else>ボーナスを受け取る</span>
      </v-btn>
      <v-btn v-else disabled block solo color="#bf9507" class="mt-2 white--text" style="border:solid black 2px;">本日のボーナスはGETしました</v-btn>
      
    </v-container>
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-actions>
          <v-btn icon @click="dialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          {{ oripa.detail }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import Api from '@/modules/api'

export default {
  name: 'BonuseCard',
  props: ['oripa'],
  data(){
    return {
      dialog: false,
      isDisabled: false
    }
  },
  methods: {
    ...mapActions(["openSnackbar", "closeSnackbar"]),
    getBonus: function(){
      console.log('bonuse: ',this.oripa)
      this.isDisabled = true
      Api.postBonuseGacha(this.oripa.oripa_id).then(res=>{
        Api.getUser()
        Api.getOripas()
        Api.getRankingOripaAll()
        this.$router.push({
          name: 'lottery', // 移動したいパスの名前（ルーター定義で指定された名前）
          params: {
            id: this.oripa.oripa_id, // Oripa の ID
            num: 1, // propsの値
            results: res["data"] // propsの値
          },
        })
      }).catch((err) => {
        // console.log(err)
        if(err.response?.status == 500){
          if(err.response.data == 'GachaError'){
            this.openSnackbar("🙇 ガチャに失敗しました...")
          }else if(err.response.data == 'NotEnoughPoints'){
            this.openSnackbar("😱 ポイントが足りません...")
            this.$router.push('/points')
          }else if(err.response.data == 'SOLDOUT'){
            this.openSnackbar("🥺 SOLD OUT...")
            Api.getOripas().then(()=>{this.$router.push('/')})
          }else if(err.response.data == 'NotExist'){
            this.openSnackbar("🫣 このオリパはまだ販売されていません...")
          }else if(err.response.data == 'AlreadyDraw'){
            this.openSnackbar("😣 これ以上引けません...")
          }else if(err.response.data == 'More'){
            this.openSnackbar("😣 ガチャ可能回数を超えています...")
          }
        }else{
          this.openSnackbar("ガチャ中に何らかのエラーが発生しました")
        }
      }).finally(() => {
        setTimeout(() => {
          this.isDisabled = false
        },2000)
      })
    }
  },
}
</script>

<style scoped>
.tham_price {
  position:absolute;
  z-index:10;
  top:10px;
  right:10px;
  font-size:12px;
  /*font-weight:bold;*/
}
</style>