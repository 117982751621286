<template>
  <v-form>
    <v-container style="max-width:600px;">
      <v-btn to="/" class="mb-5"><v-icon>mdi-arrow-left-thin</v-icon>戻る</v-btn>
      <v-row class="mb-3">
        <v-col><h2 class="white--text">新規登録</h2></v-col>
      </v-row>
      <p class="mb-0 white--text">メールアドレス</p>
      <v-text-field v-model="email" :rules="[rules.required, rules.email]" solo></v-text-field>
      <p class="mb-0 white--text">パスワード</p>
      <v-text-field
        v-model="pass"
        :append-icon="showPass?'mdi-eye':'mdi-eye-off'"
        :type="showPass ? 'text' : 'password'"
        :rules="[rules.required,rules.counterMin8,rules.counterMax32]"
        label="半角英数8文字以上"
        hint="8文字以上32文字以下の半角英数 ※英小文字・英大文字・数字をそれぞれ1文字以上含む"
        solo
        @click:append="showPass = !showPass"
      ></v-text-field>
      <v-btn class="white--text" color="black" block @click="signup" :loading="loading" :disabled="disabled">新規登録（無料）</v-btn>
      <p class="mt-5" style="color:red;">{{message}}</p>
      <p class="mt-5 white--text">本サービスの会員登録を行うと、
      <router-link to="/tos"><span class="black--text text-decoration-underline">利用規約</span></router-link>
      および<router-link to="/privacypolicy"><span class="black--text text-decoration-underline">プライバシーポリシー</span></router-link>
      に同意したものとみなします.</p>
      <v-btn style="color:#0086D2;" to="/login">ログインはこちら</v-btn>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import store from '../store'
import Utils from '@/modules/utils'

export default {
  name: 'SignUp',
  data(){
    return {
      email: '',
      pass: '',
      rules: {
        required: value => !!value || '必須項目です.',
        counterMin8: value => 8 <= value.length || '8文字以上にしてください',
        counterMax32: value => value.length <= 32 || '32文字以下にしてください',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '無効なメールアドレスです.'
        },
      },
      showPass: false,
      loading: false,
      disabled: false,
      message: '',
    }
  },
  methods: {
    ...mapActions(["openSnackbar", "closeSnackbar"]),
    signup: function(){
      this.loading = true
      this.disabled = true
      Utils.signUp(this.email,this.pass).then(res => {
        console.log(res)
        this.loading = false
        this.message = '届いたメールのリンクからメールアドレスを認証してください'
        this.openSnackbar('😗 認証リンクを送信しました')
        setTimeout(() => {this.disabled = false}, 2000)
      }).catch(err => {
        console.log(err)
        this.loading = false
        setTimeout(() => {this.disabled = false}, 2000)
        if(err.name=='InvalidPasswordException'){
          this.openSnackbar('無効なパスワードです.')
        }else if(err.name=='UsernameExistsException'){
          this.openSnackbar('このメールアドレスはすでに登録されています.')
        }else{
          this.openSnackbar('何らかのエラーが発生しました.お問い合わせください.')
        }
      })
    },
  },
}
</script>