<template>
  <v-card elevation="5" :to="`oripa/`+oripa.oripa_id" max-width="400" class="mx-auto" style="border-radius:15px;">
    <v-container class="noisy-back" style="background-color:#0086D2;border-radius:15px;">
      <v-img :src="require(`@/assets/img/tham_back${oripa.design}.png`)" height="250px" style="border:solid 3px;">
        <v-chip class="tham_price white--text" color="#bf9507" label style="border:solid 2px black;">
          残<span class="ml-1 mr-1" style="font-weight:bold;font-size:16px;">{{getRestLot(oripa)}}</span> / {{getAllLot(oripa)}}<span class="ml-3" style="font-weight:bold;font-size:16px;">{{oripa.price}}</span> pt
        </v-chip>
        
        <div class="tham_titles text-center">
          <div class="tham_subtitle">
            <p class="mb-0 text" :data-text="`${oripa.subtitle}`"><span>{{oripa.subtitle}}</span></p>
          </div>
          <p class="mb-0 tham_title"><span>{{oripa.oripa_name}}</span></p>
        </div>
        <AllThumbnail :oripa="oripa" :pattern="pattern"></AllThumbnail>
      </v-img>
      <OripaDetail v-if="getRestLot(oripa)>0" :oripa="oripa"></OripaDetail>
      <!--<v-progress-linear v-model="100*getRestLot(oripa)/oripa.all_lot" color="black" class="mt-2 progress_bar" height="20">-->
      <!--  <template v-slot:default="{ value }">-->
      <!--    <strong>{{ getRestLot(oripa) }}<span style="font-size:12px;"> / {{ getAllLot(oripa) }}</span></strong>-->
      <!--  </template>-->
      <!--</v-progress-linear>-->
      <v-overlay absolute opacity="0.3" :value="getRestLot(oripa)==0">
        <h1 style="transform: rotate(-20deg);">SOLD OUT</h1>
      </v-overlay>
    </v-container>
  </v-card>
</template>

<script>
import OripaDetail from './OripaDetail.vue'
import AllThumbnail from './AllThumbnail.vue'

export default{
  name: 'ThumbnailCard',
  props: ['oripa','pattern'],
  components: {
    AllThumbnail,
    OripaDetail,
  },
  methods: {
    getRestLot: function(oripa){
      return oripa['all_rest_lot']
      // const oripa_stock = oripa["oripa_stock"]
      // return oripa_stock.filter(card=>card["prize"]!='LAST').map(el => el["rest_lot"]).reduce(function(sum, element){return sum + element;}, 0);
    },
    getAllLot: function(oripa){
      return oripa['all_lot']
      // const oripa_stock = oripa["oripa_stock"]
      // return oripa_stock.filter(card=>card["prize"]!='LAST').map(el => el["lot"]).reduce(function(sum, element){return sum + element;}, 0);
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/holo.scss';

.titles {
  position:absolute;
  z-index: 11;
  width:100%;
  bottom:0;
}
.tham_titles {
  position:absolute;
  // background: linear-gradient(to top, rgba(225, 225, 225, 0.85),transparent);
  background: linear-gradient(to top, rgba(46, 46, 46, 0.85),transparent);
  z-index:11;
  width:100%;
  bottom:0;
  // padding-left:10px;
  // padding-top:10px;
  margin-bottom:0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size:25px;
  color:white;
  font-weight: bold;
  font-family: 'RocknRoll One', sans-serif;
  // text-shadow: 2px  2px 3px black,-2px  2px 3px black,2px -2px 3px black,-2px -2px 3px black;
}
.tham_title {
  text-shadow: 2px  2px 3px black,-2px  2px 3px black,2px -2px 3px black,-2px -2px 3px black;
}
.tham_subtitle {
  // position: absolute;
  z-index:10;
  // left:10%;
  // top:-60%;
  font-size:20px;
  // font-family: 'Reggae One', cursive;
  font-family: 'RocknRoll One', sans-serif;
  // transform: rotate(-2deg);
  // transform: translate(-50%, -50%) rotate(-3deg);
  // -webkit-transform: translate(-50%, -50%) rotate(-3deg);
  // -ms-transform: translate(-50%, -50%) rotate(-3deg);
  // text-shadow:
  //           2px 2px 2px #000, -2px -2px 2px #000,
  //          -2px 2px 2px #000,  2px -2px 2px #000;
}
p.gr_anime span{
  background: -webkit-linear-gradient( 60deg,#12d6df, #f70fff);
  background-size:400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textAnime 2s infinite;
}
// p.gr_sub_anime span{
//   background: -webkit-linear-gradient( 60deg,#faea3d, #fd644f);
//   background-size:400%;
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   animation: textAnime 15s infinite;
// }
@keyframes textAnime{
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
p.gr1 span{
  background: -webkit-linear-gradient(left, #ff26fb, #00b3ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.lot {
  font-size:15px;
}
.tham_price {
  position:absolute;
  z-index:10;
  top:10px;
  right:10px;
  font-size:12px;
  /*font-weight:bold;*/
}

.tham {
  position:absolute;
  z-index:7;
  top:50%;
  left:25%;
  opacity: 0.9;
  transform: translate(-50%, -50%) rotate(-10deg);
  -webkit-transform: translate(-50%, -50%) rotate(-10deg);
  -ms-transform: translate(-50%, -50%) rotate(-10deg);
}
.tham_sub {
  position:absolute;
  opacity: 0.9;
  transform: translate(-50%, -50%) rotate(-20deg);
  -webkit-transform: translate(-50%, -50%) rotate(-20deg);
  -ms-transform: translate(-50%, -50%) rotate(-20deg);
}
.sub1 {
  top:30%;
  left:60%;
  z-index:6;
}
.sub2 {
  top:30%;
  left:75%;
  z-index:5;
}
.sub3 {
  top:30%;
  left:90%;
  z-index:4;
}
.sub4 {
  top:70%;
  left:60%;
  z-index:3;
}
.sub5 {
  top:70%;
  left:75%;
  z-index:2;
}
.sub6 {
  top:70%;
  left:90%;
  z-index:1;
}
.progress_bar {
  color:white;
  /*font-family: 'Reggae One', cursive;*/
  font-family: 'RocknRoll One', sans-serif;
  text-shadow: 2px  2px 3px black,-2px  2px 3px black,2px -2px 3px black,-2px -2px 3px black;
}

.text {
 	position: relative;
	font-size: 15px;
	font-weight: bold;
	color: #F0F;
	-webkit-text-stroke: 4px #F0F;
}

.text::before {
	content: attr(data-text);
	position: absolute;
	color: #F0F;
	-webkit-text-stroke: 1px #FFF;
}
</style>