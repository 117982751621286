import cognito from '../cognito'
import store from '../store'
import router from '../router'
import Api from './api'

export default {
  logout(){
    console.log("=====logout=====")
    cognito.signout()
    store.commit('setUser',null)
    store.commit('setUserData',null)
    store.commit('setMyCards',null)
    store.commit('setJwtToken',null)
    router.push({path: '/login'})
    router.go(0)
  },
  login(email,password){
    console.log("=====login=====")
    return new Promise((resolve,reject) => {
      cognito.signin(email,password).then(result => {
        const user = result.idToken.payload
        const jwtToken = result.idToken.jwtToken
        console.info(jwtToken)
        store.commit('setIsConfirmed',true)
        store.commit('setUser',user)
        store.commit('setJwtToken',jwtToken)
        Api.getMyCards().then(()=>{
          Api.getUser()
        })
        Api.getDeliveryHistory()
        // Api.getRankingOripaAll()
        resolve(result)
      }).catch(err => {
        reject(err)
      })
    })
  },
  signUp(email,password){
    console.log("=====sign_up=====")
    return new Promise((resolve,reject) => {
      cognito.signUp(email,password).then(result => {
        resolve(result)
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  getSession(){
    console.log("=====get_session=====")
    return new Promise((resolve,reject)=>{
      cognito.isAuthenticated().then(result => {
        const user = result.idToken.payload
        const jwtToken = result.idToken.jwtToken
        store.commit('setIsConfirmed',true)
        store.commit('setUser',user)
        store.commit('setJwtToken',jwtToken)
        Api.getMyCards().then(()=>{
          Api.getUser()
        })
        Api.getDeliveryHistory()
        // Api.getRankingOripaAll()
        resolve(result)
      }).catch(err => {
        reject(err)
      })
    })
  }
}