<template>
  <div>
    <v-row dense>
      <v-col>
        <v-chip-group column>
          <v-chip v-if="isSaled" small color="green" class="white--text" label>発売中</v-chip>
          <v-chip v-else small color="black" class="white--text" label>未発売</v-chip>
          <v-chip v-if="oripa.max_draw<10" small color="pink" class="white--text" label><span>MAX{{oripa.max_draw}}回</span></v-chip>
          <v-chip small color="#bf9507" class="white--text" v-for="(hashtag,index) in oripa.hashtags?oripa.hashtags.split(','):[]" :key="index"><v-icon x-small>mdi-pound</v-icon>{{ hashtag }}</v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="10" class="mb-2 pb-2">
        <v-row v-if="oripa.oripa_stock.filter(card=>card.prize==`S`).length>0" class="prize_font ml-1 mr-1" dense>
          <v-col>S <span style="font-size:12px;">賞</span><span v-if="oripa.oripa_stock.filter(card=>card.prize==`S`).length>5" style="font-size:12px;">（一部）</span></v-col>
          <!-- <v-col class="text-end">{{oripa.oripa_stock.filter(card=>card.prize==`S`).map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0)}} <span style="font-size:12px;">枚</span></v-col> -->
        </v-row>
        <v-row v-if="oripa.oripa_stock.filter(card=>card.prize==`S`).length>0" dense>
          <v-col cols="auto">
            <ul style="display:flex;justify-content: center;" class="pl-0">
              <li v-for="card in oripa.oripa_stock.flatMap(item => item.prize === 'S' ? Array(item.lot).fill(item) : []).slice(0,5)" :key="card.card_id" class="mr-1 ml-1" style="list-style: none;">
                <v-img width="42px" :src="card.image" class="shiny"></v-img>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2" style="border:solid 1px white;background-color: #bf9507;" class="rounded mb-2 pb-2">
        <v-row dense class="prize_font">
          <v-col class="text-center"><span style="font-size:14px;">ラスト</span></v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <ul style="display:flex;justify-content: center;" class="pl-0">
              <li class="mr-1 ml-1" style="list-style: none;">
                <v-img v-if="oripa.oripa_stock.filter(card=>card.prize==`LAST`).length>0" width="42px" :src="oripa.oripa_stock.filter(card=>card.prize==`LAST`)[0].image" class="shiny"></v-img>
                <p v-else class="mb-0 white--text">な<br>し</p>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="auto">
        <v-row v-if="oripa.oripa_stock.filter(card=>card.prize==`A`).length>0" dense class="prize_font ml-1 mr-1">
          <v-col>A <span style="font-size:12px;">賞</span><span v-if="oripa.oripa_stock.filter(card=>card.prize==`A`).length>4" style="font-size:12px;">（一部）</span></v-col>
          <!-- <v-col class="text-end">{{oripa.oripa_stock.filter(card=>card.prize==`A`).map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0)}} <span style="font-size:12px;">枚</span></v-col> -->
        </v-row>
        <v-row v-if="oripa.oripa_stock.filter(card=>card.prize==`A`).length>0" dense>
          <v-col>
            <ul style="display:flex;justify-content: center;" class="pl-0">
              <li v-for="card in oripa.oripa_stock.filter(card=>card.prize==`A`).slice(0,4)" :key="card.card_id" class="mr-1 ml-1" style="list-style: none;">
                <v-img width="42px" :src="card.image" class="shiny"></v-img>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="4">
        <v-row v-if="oripa.oripa_stock.filter(card=>card.prize==`B`).length>0" dense class="prize_font ml-1 mr-1">
          <v-col><span style="font-size:12px;">B賞</span></v-col>
          <!-- <v-col class="text-end"><span style="font-size:12px;">{{oripa.oripa_stock.filter(card=>card.prize==`B`).map(item=>Number(item["lot"])).reduce((sum, element) => sum + element, 0)}}枚</span></v-col> -->
        </v-row>
        <v-row v-if="oripa.oripa_stock.filter(card=>card.prize==`B`).length>0" dense>
          <v-col>
            <ul style="display:flex;justify-content: center;" class="pl-0">
              <li v-for="card in oripa.oripa_stock.filter(card=>card.prize==`B`).slice(0,2)" :key="card.card_id" class="mr-1 ml-1" style="list-style: none;">
                <v-img width="42px" :src="card.image" class="shiny"></v-img>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  name: 'OripaDetail',
  props: ['oripa'],
  computed: {
    isSaled(){
      const now = new Date()
      console.log("now: ",now)
      if(Object.keys(this.oripa).length){
        const sale_ts = this.oripa.sale_ts
        console.log("oripa: ",this.oripa)
        console.log("sale_ts: ",sale_ts)
        const sale_ts_date = sale_ts.split('T')[0].split('-')
        const sale_ts_time = sale_ts.split('T')[1].split(':')
        const saleTime = new Date(Number(sale_ts_date[0]),Number(sale_ts_date[1])-1,Number(sale_ts_date[2]),Number(sale_ts_time[0]),Number(sale_ts_time[1]),Number(sale_ts_time[2]))
        console.log("saleTime: ",saleTime)
        if(saleTime < now){
          return true
        }else{
          return false
        }
      }else{
        return false
      }
    },
  },
}
</script>

<style scoped>
.prize_font {
  color:white;
  font-family: 'RocknRoll One', sans-serif;
  text-shadow: 2px  2px 3px black,-2px  2px 3px black,2px -2px 3px black,-2px -2px 3px black;
}

.shiny:after {
  position: absolute;
  top: -200%;
  left: -150%;
  content: "";
  height: 500%;
  width: 70px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6) 40%, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
  -webkit-transform: rotate(60deg);
  animation: 3s shine infinite linear;
}
@keyframes shine {
  0% {
    left: -100%;
  }
  80% {
    left: 150%;
  }
  99.9%,to {
    left: 200%;
  }
}
</style>