<template>
  <v-form>
    <v-container style="max-width:600px;">
      <v-btn to="/" class="mb-5"><v-icon>mdi-arrow-left-thin</v-icon>戻る</v-btn>
      <v-row class="mb-3">
        <v-col><h2 class="white--text">ログイン</h2></v-col>
      </v-row>
      <p class="mb-0 white--text">メールアドレス</p>
      <v-text-field v-model="email" :rules="[rules.required, rules.email]" label="example@oripa.co.jp" solo></v-text-field>
      <p class="mb-0 white--text">パスワード</p>
      <v-text-field
        v-model="pass" class="pb-1" :append-icon="showPass?'mdi-eye':'mdi-eye-off'" :type="showPass ? 'text' : 'password'"
        :rules="[rules.required]" label="半角英数8文字以上" solo @click:append="showPass = !showPass"
      ></v-text-field>
      <v-btn class="mt-5 pl-0 white--text" color="black" block @click="login">ログイン</v-btn>
      <v-row align="center" class="mt-3">
        <v-col><v-btn style="color:#0086D2;" to="/signup">新規登録はこちら</v-btn></v-col>
        <v-spacer></v-spacer>
        <v-col><v-btn text class="pl-0 white--text text-decoration-underline" @click="dialogForgetPassword=true">パスワードを忘れた方はこちら</v-btn></v-col>
      </v-row>
      
      
      <v-dialog v-model="dialogForgetPassword" persistent width="500px">
        <v-card>
          <v-card-actions>
            <v-btn icon @click="dialogForgetPassword=false;"><v-icon>mdi-arrow-left-thin</v-icon></v-btn>
          </v-card-actions>
          <v-card-title class="pt-0">パスワードをリセット</v-card-title>
          <v-card-text>
            <p>メールアドレスを入力してください。パスワードリセット用コードを送信します。</p>
            <v-row>
              <v-col cols="9"><v-text-field v-model="forgetPasswordEmail" dense :rules="[rules.required, rules.email]" label="example@oripa.co.jp" solo></v-text-field></v-col>
              <v-col cols="3"><v-btn :disabled="disabledSent" block @click="forgetPassword">送信</v-btn></v-col>
            </v-row>
            <div v-if="flagSent">
            <p>{{emailSent}}に送信されたコードと新しいパスワードを入力してください</p>
            <p class="mb-0">コード</p>
            <v-text-field v-model="code" label="000000" solo></v-text-field>
            <p class="mb-0">新しいパスワード</p>
            <v-text-field
              v-model="newPass" :append-icon="showNewPass?'mdi-eye':'mdi-eye-off'" :type="showNewPass ? 'text' : 'password'"
              :rules="[rules.required]" label="半角英数8文字以上" hint="8文字以上32文字以下の半角英数 ※英小文字・英大文字・数字をそれぞれ1文字以上含む"
              solo @click:append="showNewPass = !showNewPass"
            ></v-text-field>
            <v-btn block dark :loading="loadingReset" @click="resetPassword">パスワードリセット</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import store from '../store'
import Utils from '@/modules/utils'

export default {
  name: 'Login',
  data(){
    return {
      email: '',
      pass: '',
      rules: {
        required: value => !!value || '必須項目です.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '無効なメールアドレスです.'
        },
      },
      showPass: false,
      dialogForgetPassword: false,
      forgetPasswordEmail: '',
      disabledSent: false,
      flagSent: false,
      emailSent: '',
      code: '',
      newPass: '',
      showNewPass: false,
      loadingReset: false,
    }
  },
  methods: {
    ...mapActions(["openSnackbar", "closeSnackbar"]),
    login: function(){
      Utils.login(this.email,this.pass).then(res => {
        console.log(res)
        this.$router.push('/')
        this.openSnackbar('こんにちは！')
      }).catch(err => {
        if(err.name=='UserNotConfirmedException'){
          this.$cognito.resendConfirmationCode(this.email).then(resConfirm=>{
            console.log(resConfirm)
          }).catch(errConfirm => {
            console.log(errConfirm)
          })
          this.openSnackbar('認証URLを送信しました.確認後ログインしてください.')
        }else{
          this.openSnackbar('メールアドレスまたはパスワードが正しくないようです...')
        }
      })
    },
    forgetPassword: function(){
      this.disabledSent = true
      this.$cognito.forgetPassword(this.forgetPasswordEmail)
      .then(result => {
        console.log(result)
        this.emailSent = this.forgetPasswordEmail
        this.flagSent = true
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        setTimeout(() => {this.disabledSent = false},2000)
      })
    },
    resetPassword: function(){
      this.loadingReset = true
      this.$cognito.resetPassword(this.emailSent,this.newPass,this.code)
      .then(function(res){
        console.log(res)
        this.openSnackbar("パスワードが変更されました.")
        this.loadingReset = false
        this.dialogForgetPassword = false
        this.forgetPasswordEmail = ''
        this.flagSent = false
        this.emailSent = ''
        this.code = ''
        this.newPass = ''
        this.showNewPass = false
      }.bind(this)).catch(function(err){
        this.loadingReset = false
        console.log(err)
        switch(err.name){
          case 'CodeMismatchException':
            this.openSnackbar('認証コードが誤っています.')
          case 'InvalidPasswordException':
            this.openSnackbar('無効なパスワードです.')
          case 'ExpiredCodeException':
            this.openSnackbar('認証コードの期限切れです.')
          default:
            this.openSnackbar('時間をおいて安定した通信環境でもう一度お試しください.')
        }
      }.bind(this))
    },
  }
}
</script>