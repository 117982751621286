import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Login from "../views/Login.vue";
import SignUp from "../views/SignUp.vue";
import Oripa from "../views/Oripa.vue";
import MyCards from "../views/MyCards.vue";
import Returns from "../views/Returns.vue";
import Profile from "../views/Profile.vue";
import Points from "../views/Points.vue";
import Delivery from "../views/Delivery.vue";
import Tos from "../views/Tos.vue";
import Guide from "../views/Guide.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import Sct from "../views/Sct.vue";
import Contact from "../views/Contact.vue";
import Confirmed from "../views/Confirmed.vue";
import Lottery from "../views/Lottery.vue";
import LotteryResult from "../views/LotteryResult.vue";
import Result from "../views/Result.vue";
import Complete from "../views/Complete.vue";
import Withdrawal from "../views/Withdrawal.vue";
import PaypayConfirmed from "../views/PaypayConfirmed.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUp,
  },
  {
    path: "/oripa/:id",
    component: Oripa,
  },
  {
    path: "/mycards",
    name: "mycards",
    component: MyCards,
  },
  // {
  //   path: '/returns',
  //   name: 'returns',
  //   component: Returns
  // },
  // {
  //   path: '/delivery',
  //   name: 'delivery',
  //   component: Delivery
  // },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  // {
  //   path: '/points',
  //   name: 'points',
  //   component: Points
  // },
  {
    path: "/tos",
    name: "tos",
    component: Tos,
  },
  {
    path: "/guide",
    name: "guide",
    component: Guide,
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/sct",
    name: "sct",
    component: Sct,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/confirmed",
    name: "confirmed",
    component: Confirmed,
  },
  {
    path: "/oripa/:id/lottery",
    name: "lottery",
    component: Lottery,
    props: true,
  },
  {
    path: "/oripa/:id/lottery/result",
    name: "lottery-result",
    component: LotteryResult,
    props: true,
  },
  {
    path: "/oripa/:id/result",
    name: "result",
    component: Result,
    props: true,
  },
  {
    path: "/oripa/:id/complete",
    name: "complete",
    component: Complete,
    props: true,
  },
  {
    path: "/withdrawal",
    name: "withdrawal",
    component: Withdrawal,
  },
  {
    path: "/paypayconfirmed",
    name: "paypayconfirmed",
    component: PaypayConfirmed,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
